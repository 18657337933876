html {
  font-size: 62.5%;
}

body {
  /* margin: 0; */
  font: 300 11px/1.4 'Heveltica Neue', 'sans-serif';
  /* color: #444; */
  background: linear-gradient(to bottom, #000000, #6a0dad);
  min-height: 100vh;
  /* Ensure the gradient covers the full viewport height */
  /* margin: 0; */
  /* padding: 0; */
  color: #ffffff;
  overflow: hidden;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 1200px) {
  body {
    overflow: visible;
  }
}
.page {
    width: 100%;
    height: 100%;
    position: absolute;
}

.tags {
    color: #FF7F00;
    opacity: 0.6;
    position: absolute;
    bottom: -10px;
    left: 120px;
    font-size: 18px;
    font-family: 'La Belle Aurore';
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
}

.container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 10%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
    max-width: 100%;
}

.container.about-page,
.container.contact-page,
.container.projects-page {

    h1 {
        font-size: 53px;
        font-family: 'Coolvetica';
        color: #FF7F00;
        font-weight: 400;
        margin-top: 2%;
        position: relative;
        margin-bottom: 40px;
        left: 10px;
    }

    p {
        color: white;
        font-size: 14px;
        font-family: sans-serif;
        font-weight: 300;
        min-width: fit-content;
        animation: pulse 2s;

        &:nth-of-type(1) {
            animation-delay: 1.1s;
        }

        &:nth-of-type(2) {
            animation-delay: 1.25s;
        }

        &:nth-of-type(3) {
            animation-delay: 1.4s;
        }

        &:nth-of-type(4) {
            animation-delay: 1.55s;
        }
    }

    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;

    }

    .text-animate-hover {
        &:hover {
            color: white;
        }
    }
}

@media screen and (max-width: 1200px) {
    .page {
        position: initial;
    }

    .container {
        position: initial;
        height: auto;
        min-height: auto;

        &.about-page,
        &.contact-page,
        &.projects-page {
            .text-zone {
                position: initial;
                transform: none;
                width: 100%;
                display: block;
                padding: 20px;
                box-sizing: border-box;
                margin-top: 15%;
            }
        }
    }
}
.home-page {
    margin-top: 5%;

    .text-zone {
        position: center;
        left: 8%;
        top: 10%;
        transform: translate(10%);
        width: 45%;
        max-height: 9%;
    }

    .picture-box {
        flex: 1;
        margin-left: 50%;
        position: static;
        margin-top: -150px;

        img {
            max-width: 100%;
            height: 100%;
            border-radius: 8px;
            animation: fadeIn 3s 2.7s backwards;
            vertical-align: middle;
        }
    }

    h1 {
        color: white;
        font-size: 53px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;
    }

    h2 {
        color: #c2bfc5;
        margin-top: 120px;
        font-weight: 400;
        font-size: 11px;
        font-family: sans-serif;
        letter-spacing: 4px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
        color: #FF7F00;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 1px 8px;
        border: 1px solid #FF7F00;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        margin-top: 25px;
        white-space: nowrap;

        &:hover {
            background: #FF7F00;
            color: #ffffff;
        }
    }
}

@media screen and (max-width: 1200px) {

    .tags,
    .home-page h1::before,
    .home-page h1::after {
        display: none;
    }

    .home-page {
        .picture-box {
            position: auto;
            bottom: 0;
            opacity: 1;
            margin-left: 30%;
        }

        .picture-box img {
            max-width: 60%;
            max-height: 60%;
            animation: fadeIn 3s 2.7s backwards;
            margin-top: 50%;
        }

        .text-zone {
            position: center;
            width: 100%;
            transform: none;
            padding: 10px;
            box-sizing: border-box;
            margin-top: 15%;
            text-align: center;
        }

        .flat-button {
            float: none;
            display: block;
            margin: 20px auto 0 auto;
            width: 105px;
        }
    }
}
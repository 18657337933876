.projects-page {
    padding-left: 17%;
    padding-right: 50px;
    width: calc(100% - 150px);
    position: center;
    height: 100%;
    overflow: auto;
    // margin-top: 8%;

    h1.page-title {
        margin-left: 100px;
    }

    .page-title {
        margin-top: 10%;
    }

    .images-container {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        padding-bottom: 200px;
        max-width: 85%;
    }

    .image-box {
        position: relative;
        flex: 1 1 calc(50% - 10px);
        height: 400px;
        overflow: hidden;
        border-radius: 10px;
        max-width: 40%;
        margin-left: 50px;

        .portfolio-image {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .content {
            position: absolute;
            width: 100%;
            z-index: 3;
            padding: 10px 20px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: linear-gradient(180deg,
                    rgba(0, 0, 0, 0.2) 0,
                    rgba(0, 0, 0, 1));
            bottom: -70px;
            text-align: center;
        }

        .title {
            margin-bottom: 2px;
            margin-top: 0;
            color: whitesmoke;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
        }

        .description {
            font-size: 18px;
            margin-bottom: 1px;
            color: whitesmoke;
            font-weight: 700px;
        }

        .technology {
            font-size: 14px;
            margin-bottom: 0px;
            color: whitesmoke;
            font-weight: 600px;
        }

        .btn {
            margin-top: 30px;
            margin-bottom: 10px;
            padding: 0 23px;
            height: 40px;
            line-height: 34px;
            border: 2px solid #FF7F00;
            border-radius: 4px;
            font-size: 14px;
            color: white;
            background: transparent;
            text-transform: uppercase;
            font-weight: 700;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
        }

        .btn:hover {
            transform: translateY(-1px);
            background: #FF7F00;
        }

        &:after {
            content: "";
            background: linear-gradient(180deg, rgb(123, 0, 255), #000);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 0;
        }

        &:hover:after {
            opacity: 0.65;
        }

        &:hover .content {
            bottom: 0;
            background: transparent;
        }
    }
}

@media screen and (max-width: 1200px) {
    .container.projects-page {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        margin-top: 15%;

        h1.page-title {
            margin-top: 20px;
            margin-left: 20px;
        }

        .image-box {
            height: 200px;
            max-width: 100%;
        }

        .content {
            width: 90%;
        }
    }
}
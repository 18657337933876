#tech-grid {
    margin-left: 55%;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    width: 28%;
    margin-top: 20%;
    animation: fadeIn 20s;
    -webkit-animation: fadeIn 20s;
    -moz-animation: fadeIn 20s;
    -o-animation: fadeIn 20s;
    -ms-animation: fadeIn 20s;

}

.tech-grid-item {
    width: 20%;
    overflow: hidden;
    // animation: fadeIn 10s;
    // -webkit-animation: fadeIn 10s;
    // -moz-animation: fadeIn 10s;
    // -o-animation: fadeIn 10s;
    // -ms-animation: fadeIn 10s;

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;

        }
    }
}

@media screen and (max-width: 1200px) {
    #tech-grid {
        width: 90%;
        margin-right: 50%;
        margin-left: 8%;
        margin-top: auto;
    }
}
contact-page {
    .text-zone {
        margin-top: 10%;
    }
}

.contact-form {
    width: 100%;
    margin-top: 17px;

    ul {
        padding: 0;
        margin: 0;

        li {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            overflow: hidden;
            display: block;
            position: relative;
            opacity: 0;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
            clear: both;
        }

        li.half {
            width: 40%;
            margin-left: 2%;
            float: left;
            clear: none;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    input[type='text'],
    input[type='email'] {
        width: 100%;
        border: 0;
        background: #25063d;
        height: 50px;
        font-size: 16px;
        color: rgb(255, 255, 255);
        padding: 0 20px;
        box-sizing: border-box;
    }

    textarea {
        width: 100%;
        border: 0;
        background: #25063d;
        height: 50px;
        font-size: 16px;
        color: rgb(255, 255, 255);
        padding: 20px;
        min-height: 150px;
        box-sizing: border-box;
    }

    .flat-button {
        color: #FF7F00;
        background: 0 0;
        font: 11px;
        letter-spacing: 2px;
        text-decoration: none;
        padding: 0px 10px;
        border: 1px solid #FF7F00;
        float: right;
        border-radius: 4px;

        &:hover {
            background: #FF7F00;
            color: #ffffff;
        }
    }
}

.info-map {
    position: absolute;
    background-color: #0d335a00;
    top: 50px;
    right: 30%;
    z-index: 999999;
    width: 200px;
    padding: 20px;
    color: white;
    font-family: 'Helvetica';
    font-size: 17px;
    font-weight: 300;
    opacity: 0.85;
    animation: fadeIn 1s 1.5s;
    animation-fill-mode: forwards;
}

.map-wrap {
    background: rgba(8, 253, 216, 0.1);
    float: right;
    width: 53%;
    height: 100%;

}

.leaflet-container {
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 0;
    animation: backInRight 1s 1.2s;
    animation-fill-mode: forwards;
}

@media screen and (max-width: 1200px) {
    .map-wrap {
        float: none;
        width: 100%;
        height: 400px;
        margin: 0;
    }

    .info-map {
        display: none;
    }
}